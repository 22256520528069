import { FC, useState } from "react";
import { MavLocation } from "../dtos/MavLocation";
import { useUpdateMavLocationMutation } from "../store/MavLocationMutations";
import { Ico } from "./Ico";
import { MavTypeSelector } from "./MavTypeSelector";
import { Btn } from "./Util/Btn";
import { FormsInlineTextBox } from "./Util/FormsInlineTextBox";

export const EditModal: FC<{ mavLocation: MavLocation, cancel: () => void }> = ({ mavLocation, cancel }) => {

    const updateMavLocation = useUpdateMavLocationMutation()

    const [formData, setFormData] = useState<MavLocation>({
        ...mavLocation
    })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setFormData((prevData) => ({ ...prevData, [name]: value }))
    }

    return (
        <div className="leaflet-container p-5 bg-white flex flex-col items-center">
            <div className="w-128">
                <Btn text="Änderung Abbrechen" onClick={() => { cancel() }} />
                <br />
                <br />
                <h1>Änderung des Mav-Standorts</h1>
                <FormsInlineTextBox id="name" lableValue="Standortname" currentValue={formData.name} handleChange={handleChange} required />
                <table>
                    <tbody>
                        <tr>
                            <td></td>
                            <td><MavTypeSelector currentValue={formData.mavtype} onValueChanged={(mavtype) => setFormData(data => ({ ...data, mavtype: mavtype }))} /></td>
                        </tr>
                        <tr>
                            <td><Ico type="Gemeinde" id="Edit-Gemeinde" /></td>
                            <td><FormsInlineTextBox id="kirchengemeinde" lableValue="Neue Kirchengemeinde" currentValue={formData.kirchengemeinde} handleChange={handleChange} required /></td>
                        </tr>
                        <tr>
                            <td><Ico type="Address" id="Edit-Address" /></td>
                            <td><FormsInlineTextBox id="address" lableValue="Addresse" currentValue={formData.address} handleChange={handleChange} required /></td>
                        </tr>
                        <tr>
                            <td><Ico type="EMail" id="Edit-EMail" /></td>
                            <td><FormsInlineTextBox id="email" lableValue="E-Mail" currentValue={formData.email ?? ''} handleChange={handleChange} /></td>
                        </tr>
                    </tbody>
                </table>
                <Btn text="Ändern" onClick={() => { updateMavLocation.mutate(formData); cancel() }} />
            </div>
        </div>
    )
}