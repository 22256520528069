import { Dispatch, FC, RefObject, SetStateAction, useState } from "react"
import { useMavLocations } from "../store/MavLocationQuerys";
import Select from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { backend } from "../backend/Backend";
import { Map as LeafletMap, type Popup as PopupType } from "leaflet";
import { MavLocation } from "../dtos/MavLocation";
import { IsInEditMode } from "../EditMode";

type MAVSearchProps = {
    mapRef: RefObject<LeafletMap>,
    popups: Map<string, RefObject<PopupType | null>>,
    setSelectedMav: Dispatch<SetStateAction<MavLocation | null>>
}

export const MAVSearch: FC<MAVSearchProps> = ({ mapRef, popups, setSelectedMav }) => {
    const { mavLocations } = useMavLocations()
    const [show, setShow] = useState(false)

    const highlightById = (id: string) => {
        try {
            const mav = mavLocations.find(m => m.id === id)
            if (!mav) throw new Error('MAV not found: ' + id)
            if (!mapRef.current) throw new Error('Map not found')
            const map = mapRef.current
            map.flyTo(mav?.position, mapRef.current.getZoom(), { animate: true, duration: 2 });
            setTimeout(() => {
                map.setZoom(13, { animate: true, duration: 1 })
                const popup = popups.get(mav.id)?.current
                popup?.setLatLng(mav.position)
                setTimeout(() => {
                    map.openPopup(popup!)
                }, 1000)
            }, 2000);
        } catch (error) {
            console.error(error)
            backend.log(error)
            alert(JSON.stringify(error))
        }
    }

    return (
        <div>
            <FontAwesomeIcon icon={faSearch} className="mx-3 pt-5 pb-2" onClick={() => setShow(s => !s)} />
            {show ? <div className="w-96">
                <Select
                    placeholder='MAV-Standort suchen'
                    options={mavLocations.map(i => ({ value: i.id, label: i.name }))}
                    onChange={(e: any) => {
                        const mavId = e.value
                        if (!IsInEditMode()) {
                            highlightById(mavId)
                        } else {
                            setSelectedMav(mavLocations.find(m => m.id === mavId) ?? null)
                        }
                    }}
                />
            </div>
                : <></>}
        </div>
    )
}
