import { Marker, Popup } from "react-leaflet"
import { FC, MutableRefObject, useEffect, useRef, useState } from "react"
import { MavLocation, MavTypeDisplayName } from "../dtos/MavLocation"
import { houseIcon } from "../assets/HouseIcon"
import { useDeleteMavLocationMutation, useUpdateMavLocationMutation } from "../store/MavLocationMutations"
import { Btn } from "./Util/Btn"
import { FormsInlineTextBox } from "./Util/FormsInlineTextBox"
import { Ico } from "./Ico"
import { MavTypeSelector } from "./MavTypeSelector"
import { IsInEditMode } from "../EditMode"
import { Map as LeafletMap, type Popup as PopupType } from "leaflet";

type MavLocationMarkerProps = {
    mavLocation: MavLocation,
    color: string,
    mapRef: MutableRefObject<LeafletMap | null>,
    popupRefs: Map<string, MutableRefObject<PopupType | null>>
}

export const MavLocationMarker: FC<MavLocationMarkerProps> = ({ mavLocation, color, mapRef, popupRefs }) => {

    const [editing, setEditing] = useState<boolean>(false)
    const popupRef = useRef<PopupType>(null)
    const deleteMavLocation = useDeleteMavLocationMutation()
    const updateMavLocation = useUpdateMavLocationMutation()

    const [formData, setFormData] = useState<MavLocation>({
        ...mavLocation
    })

    useEffect(() => {
        popupRefs.set(mavLocation.id, popupRef)
    }, [popupRefs, popupRef])

    useEffect(() => {
        if (mapRef.current === null) return
        const currentMap = mapRef.current

        const reset = () => {
            setEditing(false)
            setFormData({ ...mavLocation })
        }

        currentMap.addEventListener('popupclose', reset)

        return () => { currentMap.removeEventListener('popupclose', reset) }
    }, [mapRef, mavLocation])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setFormData((prevData) => ({ ...prevData, [name]: value }))
    }

    const editingContent = (
        <div style={{ display: editing ? 'unset' : 'none' }}>
            <FormsInlineTextBox id="name" lableValue="Standortname" currentValue={formData.name} handleChange={handleChange} required />
            <table>
                <tbody>
                    <tr>
                        <td></td>
                        <td><MavTypeSelector currentValue={formData.mavtype} onValueChanged={(mavtype) => setFormData(data => ({ ...data, mavtype: mavtype }))} /></td>
                    </tr>
                    <tr>
                        <td><Ico type="Gemeinde" id="Edit-Gemeinde" /></td>
                        <td><FormsInlineTextBox id="kirchengemeinde" lableValue="Neue Kirchengemeinde" currentValue={formData.kirchengemeinde} handleChange={handleChange} required /></td>
                    </tr>
                    <tr>
                        <td><Ico type="Address" id="Edit-Address" /></td>
                        <td><FormsInlineTextBox id="address" lableValue="Addresse" currentValue={formData.address} handleChange={handleChange} required /></td>
                    </tr>
                    <tr>
                        <td><Ico type="EMail" id="Edit-EMail" /></td>
                        <td><FormsInlineTextBox id="email" lableValue="E-Mail" currentValue={formData.email ?? ''} handleChange={handleChange} /></td>
                    </tr>
                </tbody>
            </table>
            <Btn text="Ändern" onClick={() => { setEditing(false); updateMavLocation.mutate(formData) }} />
        </div>
    )

    const staticContent = (
        <div style={{ display: editing ? 'none' : 'unset' }}>
            <h1 className="text-xl font-bold mb-2">{mavLocation.name}{mavLocation.mavtype !== "Unknown" && <><br />({MavTypeDisplayName(mavLocation.mavtype)})</>}</h1>
            <table>
                <tbody>
                    <tr>
                        <td><Ico type="EMail" id="EMail" /></td>
                        <td>{mavLocation.email === null ? '-' : <a href={"mailto:" + mavLocation.email}>{mavLocation.email}</a>}</td>
                    </tr>
                </tbody>
            </table>
            {IsInEditMode() &&
                <div className="flex flex-row justify-between">
                    <Btn text="Löschen" onClick={() => deleteMavLocation.mutate(mavLocation.id)} slim />
                    <Btn text="Ändern" onClick={() => setEditing(true)} slim />
                </div>
            }
        </div>
    )

    return (
        <Marker position={mavLocation.position} icon={houseIcon(color, "mav-location" + mavLocation.id)}>
            <Popup ref={popupRef}>
                {staticContent}
                {editingContent}
            </Popup>
        </Marker>
    )
} 